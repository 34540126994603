<template>
  <layout-empty>
    <component :is="layoutComponent" :step="step" v-model="step">
      <template #1>
        <accept-licence
          @next="gotoAddGateway"
          :show-buttons="step === welcomeStep"
        />
      </template>

      <template #2>
        <add-gateway-card
          @back="step = welcomeStep"
          @next="step = web485KeyStep"
          :disabled="hasGateway"
          :show-buttons="step === addGatewayStep"
        />
      </template>

      <template #3>
        <web485-key
          @next="goToDeviceScan"
          :show-buttons="step === web485KeyStep"
        />
      </template>

      <template #4>
        <device-scan-initial
          @back="step = web485KeyStep"
          @complete="complete"
          :show-buttons="step === deviceScanStep"
        >
          <v-alert>
            {{ $t("setup.step4.explanation") }}
          </v-alert>
        </device-scan-initial>
      </template>
    </component>
  </layout-empty>
</template>

<script>
import { mapGetters } from "vuex";
import AcceptLicence from "@components/admin/setup/AcceptLicence";
import AddGatewayCard from "@components/admin/setup/AddGatewayCard";
import SetupLayoutDesktop from "@components/admin/setup/layout/SetupLayoutDesktop";
import SetupLayoutPortrait from "@components/admin/setup/layout/SetupLayoutPortrait";
import AddGatewayQR from "@components/admin/setup/AddGatewayQR";
import Web485Key from "@components/admin/setup/Web485Key";
import DeviceScanInitial from "@components/admin/setup/DeviceScanInitial";

export default {
  name: "SetupPage",
  components: {
    DeviceScanInitial,
    Web485Key,
    AddGatewayQR,
    SetupLayoutDesktop,
    SetupLayoutPortrait,
    AddGatewayCard,
    AcceptLicence,
  },
  data() {
    return {
      step: 1,
      welcomeStep: 1,
      addGatewayStep: 2,
      web485KeyStep: 3,
      deviceScanStep: 4,
      refreshTimerId: null,
      refreshInterval: 10 * 1000,
    };
  },
  computed: {
    ...mapGetters(["getOwnedGateways"]),
    ...mapGetters("layout", ["isPortrait"]),
    layoutComponent() {
      if (this.isPortrait) {
        return SetupLayoutPortrait;
      }
      return SetupLayoutDesktop;
    },
    hasGateway() {
      return this.getOwnedGateways.length > 0;
    },
  },
  methods: {
    gotoAddGateway() {
      this.step = this.addGatewayStep;
      setTimeout(() => {
        if (this.hasGateway) {
          this.step = this.web485KeyStep;
        }
      }, 500);
    },
    async goToDeviceScan() {
      this.step = this.deviceScanStep;
      await this.refresh();
      this.refreshTimerId ??= setInterval(this.refresh, this.refreshInterval);
    },
    async refresh() {
      await this.$store.dispatch("subscription/initSupportedDevices", true);
    },
    async complete(devices) {
      await this.$store.dispatch("subscription/checkout", {
        successUrl: "/admin/subscription",
        cancelUrl: "/",
        devices,
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
