<template>
  <v-form ref="termsForm" v-model="valid">
    <v-card outlined>
      <v-container fluid>
        <v-card-title>
          {{ $t("setup.step1.welcome") }}
        </v-card-title>

        <v-alert>
          {{ $t("setup.step1.explanation") }}
        </v-alert>

        <base-checkbox
          v-model="acceptedLicence"
          :rules="acceptedLicenceRules"
          class="ms-2"
          required
        >
          <template #label>
            <span>
              {{ $t("setup.step1.readAndAccept") }}
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <a
                    :href="termsUrl"
                    rel="noopener noreferrer"
                    target="_blank"
                    v-on="on"
                    @click.stop
                  >
                    {{ $t("setup.step1.termsAndConditions") }}
                  </a>
                </template>
                {{ $t("setup.step1.opensInNewWindow") }}
              </v-tooltip>
            </span>
          </template>
        </base-checkbox>
      </v-container>
    </v-card>

    <button-holder v-if="showButtons">
      <template #btn1>
        <base-button outlined text @click="$helpers.pushRoute('/logout')">
          {{ $t("common.logout") }}
        </base-button>
      </template>
      <template #btn2>
        <base-button
          :disabled="!getHasAcceptedLicence && !valid"
          @click="validate"
        >
          {{ $t("common.next") }}
        </base-button>
      </template>
    </button-holder>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";

export default {
  name: "AcceptLicence",
  components: { ButtonHolder },
  props: {
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      termsUrl: "https://www.stienen.com/algemene-voorwaarden/",
      acceptedLicence: false,
      acceptedLicenceRules: [
        (v) => !!v || this.$t("setup.step1.agreeToContinue"),
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["getHasAcceptedLicence"]),
  },
  methods: {
    async validate() {
      this.$refs.termsForm.validate();
      if (!this.valid) {
        return;
      }
      const accepted = await this.$store.dispatch("auth/acceptLicence");
      if (accepted) {
        this.$emit("next");
      }
    },
  },
  mounted() {
    this.acceptedLicence = this.getHasAcceptedLicence;
  },
};
</script>

<style scoped></style>
